import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import CurriculumPage from "./pages/CurriculumPage";
import SelfLearningPage from "./pages/SelfLearningPage";
import SelfLearningDetailPage from "./pages/SelfLearningDetailPage";
import TraningCenterPage from "./pages/TrainingCenterPage";

function App() {
  return (
    <>
      <Header></Header>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/academic/:uid" element={<CurriculumPage />} />
        <Route path="/self-learning" element={<SelfLearningPage/>} />
        <Route path="/self-learning/:uid" element={<SelfLearningDetailPage/>} />
        <Route path="/tranining-center" element={<TraningCenterPage/>} />
      </Routes>
      <Footer></Footer>
    </>
  );
}

export default App;
