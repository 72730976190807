import React, { useState } from "react";
import krData from "../data/kr.json";
import SubjectItem from "../components/SubjectItem";
import "../css/homePage.css";
import { Link } from "react-router-dom";

const HomePage = () => {
  const [data] = useState(krData);

  // const categoryList = data.map((categoryData, index) => {
  //   const subjectList = categoryData.items.map((subjectData) => {
  //     return (
  //       <SubjectItem
  //         key={subjectData.uid}
  //         data={subjectData}
  //         category={categoryData.name}
  //       ></SubjectItem>
  //     );
  //   });

  //   return (
  //     <>
  //       <p>{categoryData.name}</p>
  //       <div>{subjectList}</div>
  //     </>
  //   );
  // });

  const academicItems = data.map((item)=>{
    return <SubjectItem
           key={item.uid}
           data={item}
        ></SubjectItem>
  })

  return (
    <main id="homepage">
      <section id="main-nav-container">
        <div id="self-study-container">
          <div className="design-line"></div>
          <div>
            <ul>
              <li>독학팁을 얻고 싶다면?</li>
              <li><Link to={"/self-learning"}>독학팁 보러가기 -></Link></li>
            </ul>
            <div></div>
            <ul>
              <Link to={"#"}><li>EP1 독학의 장점과 단점</li></Link>
              <Link to={"#"}><li>EP2 효율적인 독학을 위한 시간관리 팁</li></Link>
              <Link to={"#"}><li>EP3 온라인 리소스를 활용한 독학</li></Link>
            </ul>
          </div>
        </div>

        <div id="tranning-center-container">
          <div className="design-line"></div>
          <div>
            <ul>
              <li>무료수업제공 훈련기관을 알고 싶다면?</li>
              <li><Link to={"/tranining-center"}>훈련기관 보러가기 -></Link></li>
            </ul>
            <div></div>
            <ul>
              <Link to={"#"}><li>하이미디어 아카데미</li></Link>
              <Link to={"#"}><li>KH 아카데미</li></Link>
              <Link to={"#"}><li>코리아 IT 아카데미</li></Link>
            </ul>
          </div>
        </div>
      </section>
      <section id="academic-container">
          <p>Academic</p>
        <div>
          {academicItems}
        </div>
      </section>
    </main>
  );
};

export default HomePage;
