import React from "react";
import "../css/curriculumPage.css";
import { useParams } from "react-router-dom";
import krData from "../data/kr.json";
import CurriculumItem from "../components/CurriculumItem";

const CurriculumPage = () => {
  const { uid } = useParams();

  const academicData = krData.filter((element)=>{
      return element.uid === Number(uid)
  });
  

  const result = academicData[0].curriculum.map((element)=>{
      return <CurriculumItem key={academicData[0].uid} data={element}></CurriculumItem>
  })

  return (
    <main id="curriculumpage">
      <section id="academic-introduction-container">
        <p>{academicData[0].title}</p>
        <p>{academicData[0].introduction}</p>
      </section>

      <section id="academic-curriculum-container">
        <p>Curriculum</p>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          {result}
        </div>
      </section>
    </main>
  );
};

export default CurriculumPage;
