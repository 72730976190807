import React from 'react';
import "../css/trainingCenterPage.css";
import { Link } from 'react-router-dom';

const TrainingCenterPage = () => {
    return (
        <main id='main-training-center'>
            <section>
                <p>훈련기관</p>
                <div id="container-training-center-ad-item">
                    <Link to={"#"}><div className='training-center-ad-item'>하이미디어아카데미</div></Link>
                    <Link to={"#"}><div className='training-center-ad-item'>하이미디어아카데미</div></Link>
                    <Link to={"#"}><div className='training-center-ad-item'>하이미디어아카데미</div></Link>
                    <Link to={"#"}><div className='training-center-ad-item'>하이미디어아카데미</div></Link>
                </div>

                <div id='design-line'></div>

                <div id='container-training-center-item'>
                    <Link to={"#"}><div className='training-center-item'>게임아카데미미</div></Link>
                    <Link to={"#"}><div className='training-center-item'>게임아카데미미</div></Link>
                    <Link to={"#"}><div className='training-center-item'>게임아카데미미</div></Link>
                    <Link to={"#"}><div className='training-center-item'>게임아카데미미</div></Link>
                    <Link to={"#"}><div className='training-center-item'>게임아카데미미</div></Link>
                </div>
            </section>
        </main>
    );
};

export default TrainingCenterPage;