import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Item = styled.div`
  width: 188px;
  height: 80px;
  background-color: #218CF7;
  color: #fff;
  font-size: 18px;
  line-height: 80px;
  text-align: center;
  border-radius: 8px;

  @media (max-width: 480px) {
    width:100%;
    height:40px;
    line-height:40px;
    font-size:14px;
  }
}
`;

const SubjectItem = ({ data }) => {
  return (
    <Link to={`/academic/${data.uid}`}>
      <Item>
        <p>{data.title}</p>
      </Item>
    </Link>
  );
};

export default SubjectItem;
