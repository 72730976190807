import React from "react";
import styled from "styled-components";

const StyledFooter = styled.footer`
  min-width: 1168px;
  height:160px;
  padding: 0px 16px;
  background-color: #fff;
  color: #818181;
  font-size: 14px;
  font-family: Spoqa Han Sans Neo;
  box-shadow:0px -2px 2px #E5E5E5;

  div{
    width:1168px;
    margin:0 auto;
    height:100%;
    padding:0px 0px 16px 0px;
    box-sizing:border-box;
  }

  div > ul{
    display:flex;
    flex-direction:column;
    justify-content:flex-end;
    gap:8px;
    height:100%;
  }

  @media (max-width: 480px) {
    min-width: auto;

    div{
      width:auto;
    }
  }
`;

const Footer = () => {
  return (
    <StyledFooter>
      <div>
        <ul>
          <li>(주)킹메이커스</li>
          <li>사업자등록번호 : </li>
          <li>E-mail : devtiger.academy@gmail.com</li>
          <li>주소 : 서울특별시 동대문구 답십리로 57길 3-9</li>
        </ul>
      </div>
    </StyledFooter>
  );
};

export default Footer;
