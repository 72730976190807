import React, {  useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import "../css/selfLearningDetailPage.css"
import selfLearningDatas from "../data/selfLearning/selfLearning.json";

const SelfLearningDetailPage = () => {
    const { uid } = useParams();
    const [selfLearningData,setSelfLearningData] = useState({
        title:"",
        content:""
    });

    useEffect(()=>{
        const filterData = selfLearningDatas.filter((element)=>{
            return element.uid === Number(uid);
        })
    
        setSelfLearningData(filterData[0]);
    },[uid]);

    return (
        <main id='main-self-learning-detail'>
            <p>{selfLearningData.title}</p>
            <div>{selfLearningData.content}</div>
        </main>
    );
};

export default SelfLearningDetailPage;