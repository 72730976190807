import React, { useState } from 'react';
import "../css/selfLearningPage.css";
import selfLerning from "../data/selfLearning/selfLearning.json";
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const SelfLearningItem = styled.div`
    width:100%;
    height:64px;
    background-color:#218CF7;
    border-radius:8px;
    padding:0px 20px;
    box-sizing:border-box;
    line-height:64px;
    color:#fff;
    text-overflow:ellipsis;
    overflow: hidden;
    white-space: nowrap;

    @media (max-width: 480px) {
        font-size:14px;
        height:48px;
        line-height:48px;
    }
`

const SelfLearningPage = () => {
    const [selfLearningData] = useState(selfLerning);

    const selfLearningList = selfLearningData.map((element)=>{

        return <Link to={`/self-learning/${element.uid}`}><SelfLearningItem>
            {element.title}
        </SelfLearningItem></Link>
    });

    return (
        <main id='main-self-learning'>
            <p>독학 TIP</p>
            <div id='self-learning-container'>
                {selfLearningList}
            </div>
        </main>
    );
};

export default SelfLearningPage;